import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class Internationalisation extends React.Component {
    render() {
        const internationalisationTextBanner = get(this, 'props.data.internationalisationTextBanner');
        const internationalisationOverlayBlockTeam = get(this, 'props.data.internationalisationOverlayBlockTeam');
        const internationalisationStaffMembersBlock = get(this, 'props.data.internationalisationStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={internationalisationTextBanner.textOne} textTwo={internationalisationTextBanner.textTwo} />
                            <OverlayBlockTeam image={internationalisationOverlayBlockTeam.backgroundImage.url} headingText={internationalisationOverlayBlockTeam.headingText}
                                descriptionText={internationalisationOverlayBlockTeam.descriptionText} boxOneNumber={internationalisationOverlayBlockTeam.boxOneNumber}
                                boxOneText={internationalisationOverlayBlockTeam.boxOneText} boxTwoNumber={internationalisationOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={internationalisationOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={internationalisationStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default Internationalisation;

export const query = graphql`
query Internationalisation {
    internationalisationTextBanner: contentfulTextBanner(textBannerName: {eq: "internationalisationTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    internationalisationOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "internationalisationOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    internationalisationStaffMembersBlock: allContentfulStaffMembersBlock(
        filter: {staffMembersBlockName: {glob: "*internationalisationStaffMembersBlock*"}}
      ) {
        nodes {
          name
          description
          image {
            url
          }
        }
    }
}
`
